import React from "react"
import { Link } from "gatsby"

function CookiesModal() {
  const [cookieAccept, setCookieAccept] = React.useState(
    localStorage.getItem("cookie-accept")
  )

  const onClick = () => {
    localStorage.setItem("cookie-accept", true)
    setCookieAccept(true)
  }

  return (
    !cookieAccept && (
      <div className="cookie-modal">
        <span>
          Ten serwis do prawidłowego działania wykorzystuje pliki cookies. |{" "}
          <Link to="/cookies">Więcej informacji</Link>
        </span>
        <button className="cookie-button" onClick={onClick}>
          Rozumiem
        </button>
      </div>
    )
  )
}

export default CookiesModal
