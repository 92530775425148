import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { createGlobalStyle } from "styled-components"
import styled from "styled-components"
import { Nav, Navbar, NavDropdown } from "react-bootstrap"
import { Helmet } from "react-helmet"

import logo from "../../assets/images/logo.png"
import logoWhite from "../../assets/images/logo-white.png"
import facebookIcon from "../../assets/images/facebook.svg"
import instagramIcon from "../../assets/images/instagram.svg"

const GlobalStyle = createGlobalStyle`
  /* @import url('');
  @import url(''); */

  body {
    margin-top: 5rem;
    background-color: rgb(253, 250, 250);
    font-family: 'Roboto', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Work Sans", sans-serif!important;
    font-weight: bold;
  }

  button.button-primary {
    height: 50px;
    padding: 0 30px;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    font-weight: bold;
    color: white;
    background-color: #E5007D;
    border: 1px solid #E5007D;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
  }

  button.button-primary:hover {
    opacity: 0.85;
  }

  button.button-white {
    height: 50px;
    padding: 0 30px;
    font-size: 0.9rem;
    font-weight: bold;
    color: #E5007D;
    background-color: white;
    border: 1px solid #E5007D;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
  }

  button.button-white:hover {
    opacity: 0.8;
  }

  nav {
    height: 5rem;
    background-color: white;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  }

  .page-header {
    position: relative;
  }

  .page-header::after {
    content: '';
    height: 10px;
    width: 8rem;
    background-color: #E5007D;
    position: absolute;
    left: calc(50% - 4rem);
    bottom: -1rem;
  }

  .m-6 { 
    margin: 6rem;
  }

  .mt-6 { 
    margin-top: 6rem;
  }

  .mb-6 { 
    margin-bottom: 6rem;
  }

  nav span.nav-span {
    display: inline-block;
    padding: 8px;
    /* text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 1px;
    color: #1A1A18!important; */
  }

  .navbar-nav {
    margin-left: auto; 
  }

  nav .navbar-collapse {
    background-color: white;
  }

  nav .dropdown-menu {
    padding-left: 10px;
    border: none;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  }

  img.logo-image {
    height: 3.5rem;
  }

  .carousel-item img {
    height: calc(100vh - 5rem);
    object-fit: cover;
    object-position: center;
  }

  .social-media {
    cursor: pointer;
  }

  .social-media:hover {
    opacity: 0.8;
  }

  .cookie-modal {
    position: fixed;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
    margin: 0;
    padding: 1rem 2rem;
    color: #fafafa;
    background-color: rgba(50, 50, 50, 0.8);
    z-index: 15;
  }

  .cookie-modal a {
    color: #E5007D;
  }

  .cookie-button {
    padding: 0.5rem 1.5rem;
    color: #fafafa;
    background-color: #E5007D;
    border: 1px solid #E5007D;
    border-radius: 3px;
  }

  .cookie-button:hover {
    background-color: #ff038e;
  }
`

const Footer = styled.footer`
  position: relative;
  padding-bottom: 5rem;
  background-color: #1b1b3a;
  white-space: normal !important;
  word-wrap: break-word;

  ul {
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  .text-grey {
    color: rgba(255, 255, 255, 0.7);
  }

  .footer-cookies {
    position: absolute;
    bottom: 0;
    text-align: center;
  }
`

const Small = styled.span`
  font-size: 0.9rem;
`

const ExtraSmall = styled.span`
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.5);
`

function Layout({ children }) {
  const [showCollapse, setShowCollapse] = React.useState(false)
  const [showDropdown, setShowDropdown] = React.useState(false)
  const useActiveClass = linkLocation => {
    return linkLocation === window.location.pathname
      ? "nav-link active"
      : "nav-link"
  }
  const data = useStaticQuery(graphql`
    {
      allContentfulContactInfo {
        nodes {
          email
          numer
          adres
          godzinyTydzien
          godzinyWeekened
        }
      }
    }
  `)
  const {
    allContentfulContactInfo: { nodes },
  } = data

  const {
    email,
    numer: phoneNumber,
    adres: adress,
    godzinyTydzien: weekTimes,
    godzinyWeekened: weekendTimes,
  } = nodes[0]

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"
        ></link>
        <meta name="authors" content="Marcin Papież"></meta>
        <meta name="theme-color" content="#E5007D"></meta>
        <meta
          name="description"
          content="Gabinet Fizjoterapii i Masażu Fizjowsulkowski oferuje indywidualną fizjoterapię popartą wieloletnim doświadczeniem. Zapraszam na indywidualne konsultacje!"
        ></meta>
        <meta
          name="keywords"
          content="fizjoterapeuta, Wojciech Sułkowski, fizjoterapeuta limanowa, kosmoestetic, masaz, masaż,"
        ></meta>
      </Helmet>
      <GlobalStyle />
      <Navbar collapseOnSelect expand="lg" fixed="top" expanded={showCollapse}>
        <Navbar.Brand href="/">
          <img src={logo} alt="Fizjowsulkowski" className="logo-image" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setShowCollapse(!showCollapse)}
        />
        <Navbar.Collapse id="basic-navbar-nav" className="p-3 p-lg-0">
          <Nav className="ml-auto d-flex align-items-center">
            <span className="nav-span">
              <Link
                className={useActiveClass("/")}
                aria-current="page"
                to="/"
                onClick={() => {
                  if (showCollapse) {
                    setShowCollapse(!showCollapse)
                  }
                  if (showDropdown) {
                    setShowDropdown(!showDropdown)
                  }
                }}
              >
                Strona główna
              </Link>
            </span>
            <span className="nav-span">
              <Link
                className={useActiveClass("/about")}
                to="/about"
                onClick={() => {
                  if (showCollapse) {
                    setShowCollapse(!showCollapse)
                  }
                  if (showDropdown) {
                    setShowDropdown(!showDropdown)
                  }
                }}
              >
                O mnie
              </Link>
            </span>
            <span className="nav-span">
              <Link
                className={useActiveClass("/gallery")}
                to="/gallery"
                onClick={() => {
                  if (showCollapse) {
                    setShowCollapse(!showCollapse)
                  }
                  if (showDropdown) {
                    setShowDropdown(!showDropdown)
                  }
                }}
              >
                Galeria
              </Link>
            </span>
            <NavDropdown
              title="Informacje"
              style={{ padding: "8px" }}
              id="collasible-nav-dropdown"
              show={showDropdown}
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <Link
                className={useActiveClass("/prices")}
                to="/prices"
                onClick={() => {
                  if (showCollapse) {
                    setShowCollapse(!showCollapse)
                  }
                }}
              >
                Cennik
              </Link>
              <Link
                className={useActiveClass("/services")}
                to="/services"
                onClick={() => {
                  if (showCollapse) {
                    setShowCollapse(!showCollapse)
                  }
                }}
              >
                Usługi
              </Link>
              <Link
                className={useActiveClass("/voucher")}
                to="/voucher"
                onClick={() => {
                  if (showCollapse) {
                    setShowCollapse(!showCollapse)
                  }
                }}
              >
                Bon Podarunkowy
              </Link>
            </NavDropdown>
            <span className="nav-span">
              <Link
                className={useActiveClass("/contact")}
                to="/contact"
                onClick={() => {
                  if (showCollapse) {
                    setShowCollapse(!showCollapse)
                  }
                  if (showDropdown) {
                    setShowDropdown(!showDropdown)
                  }
                }}
              >
                Kontakt
              </Link>
            </span>
            <span className="nav-span">
              <Link
                to="https://zarejestrowani.pl/w/omIHBFQCnEd7gU0cWH1Yjw/"
                target="_blank"
                onClick={() => {
                  if (showCollapse) {
                    setShowCollapse(!showCollapse)
                  }
                  if (showDropdown) {
                    setShowDropdown(!showDropdown)
                  }
                }}
              >
                <button className="button-primary">Umów się</button>
              </Link>
            </span>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {children}
      <Footer className="container-fluid">
        <div className="row ">
          <div className="col-12 col-lg-3 text-center">
            <h3 className="mt-5">
              <img src={logoWhite} className="logo-image" alt="logo white" />
            </h3>
          </div>
          <div className="col-6 col-lg-2 mt-5 text-center text-lg-left">
            <h5 className="text-white">Obserwuj</h5>
            <div>
              <a
                className="p-2 social-media"
                href="https://www.facebook.com/profile.php?id=100087152886068"
              >
                <img src={facebookIcon} alt="Facebook" />
              </a>
              <a
                className="p-2 social-media"
                href="https://www.instagram.com/fizjowsulkowski_fizjoterapia"
              >
                <img src={instagramIcon} alt="Instagram" />
              </a>
            </div>
          </div>
          <div className="col-6 col-lg-3 mt-5 text-center text-lg-left">
            <h5 className="text-white">Godziny otwarcia:</h5>
            <ul>
              {weekTimes && weekTimes !== "brak" && (
                <li className="text-grey">
                  <Small>pon-pt: {weekTimes}</Small>
                </li>
              )}
              {!!weekendTimes && weekendTimes !== "brak" && (
                <li className="text-grey">
                  <Small>sobota: {weekendTimes}</Small>
                </li>
              )}
            </ul>
          </div>
          <div className="col-12 col-lg-4 mt-5 text-center text-lg-left">
            <h5 className="text-white">Dane kontaktowe:</h5>
            <ul>
              <li className="text-grey">
                <Small>tel: {phoneNumber}</Small>
              </li>
              <li className="text-grey">
                <Small>e-mail: {email}</Small>
              </li>
              <li className="text-grey">
                <Small>
                  adres: {adress},
                  <br />
                  <ExtraSmall>
                    *w KosmoEstetic, wejście od tyłu budynku
                  </ExtraSmall>
                </Small>
              </li>
            </ul>
          </div>
          <div className="col-12 footer-cookies pb-2">
            <Link to="/cookies">
              <Small className="text-grey">
                <u>Polityka Prywatności</u>
              </Small>
            </Link>
          </div>
        </div>
      </Footer>
    </>
  )
}

export default Layout
