// require("bootstrap/dist/css/bootstrap.min.css")
// // require("jquery/dist/jquery.min.js")
// require("popper.js/dist/popper.min")
// require("bootstrap/dist/js/bootstrap.min.js")
require("bootstrap/dist/css/bootstrap.min.css")

const React = require("react")
const Layout = require("./src/components/layout").default
const CookiesModal = require("./src/containers/CookiesModal").default

// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {
  return (
    <Layout {...props}>
      {element}
      <CookiesModal />
    </Layout>
  )
}
